<template>
  <v-row align="center" justify="center" class="pa-4">
    <v-col>
      <h1 style="font-weight: 800; color: #FF0000">รหัสบัตรเงินสดทั้งหมด</h1>
    </v-col>
    <v-card class="pa-8" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-8"
            ><span class="pt-/">สถานะ</span>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-switch v-model="activeFlag" label="ใช้งาน" disabled></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left"
            ><span class="pt-2">ชื่อบัตรเงินสด</span>
          </v-col>
          <v-col cols="12" md="7" sm="7">
            <span>{{ voucherName }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left"
            ><span class="pt-2">มูลค่าบัตรเงินสด</span>
          </v-col>
          <v-col cols="12" md="7" sm="7">
            <span>{{ voucherPrice }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left"
            ><span class="pt-2">จำนวนบัตรเงินสด</span>
          </v-col>
          <v-col cols="12" md="7" sm="7">
            <span>{{ voucherAmt }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left"
            ><span class="pt-2">ระยะเวลาของบัตรเงินสด</span>
          </v-col>

          <span>{{ datefrom }} - {{ dateto }}</span>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left mt-5"
            ><span class="pt-2">ลดเฉพาะบริษัทหรือไม่ ?</span>
          </v-col>
          <v-col cols="12" md="7" sm="7">
            <v-checkbox
              v-model="eppFlag"
              label="ลดเฉพาะบริษัท"
              disabled
            ></v-checkbox>
          </v-col>
        </v-row>
        <!-- <v-row v-if="checkCompany"> -->
        <v-row v-if="eppFlag == true">
          <v-col cols="12" md="3" sm="3" class="text-left"
            ><span class="pt-2">ชื่อบริษัท</span>
          </v-col>
          <v-col cols="12" md="7" sm="7">
            <span>{{ company }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4" sm="12" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <ExportVoucher :data="items" :formDate="datefrom" :toDate="dateto" />
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <v-chip color="red" dark
              >ใช้งานไปแล้ว {{ voucherUsedAmt }} โค้ด</v-chip
            >
          </v-col>
        </v-row>
        <v-row>
          <v-card outlined width="100%">
            <v-data-table
              :headers="headersVoucher"
              :items="items"
              :search="search"
              :items-per-page="10"
              class="elevation-1"
            >
              <template v-slot:[`item.name`]="{ item }">
                <span v-if="item.userId"
                  >{{ item.user.firstname }} {{ item.user.lastname }}</span
                >
              </template>
              <template v-slot:[`item.activeFlag`]="{ item }">
                <v-chip class="orange lighten-2" v-if="item.activeFlag == true"
                  >ยังไม่ได้ใช้งาน</v-chip
                >
                <v-chip
                  class="grey lighten-9 white--text"
                  v-if="item.activeFlag == false"
                  >ใช้งานแล้ว</v-chip
                >
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-row justify="center">
                  <!-- <v-icon @click="ViewVoucher(item)">mdi-eye</v-icon> -->
                  <v-icon @click="UpdateVoucher(item)" class="mx-2"
                    >mdi-pencil</v-icon
                  >
                  <v-icon @click="DeleteVoucher(item)">mdi-delete</v-icon>

                  <!-- <v-col cols="4" class="mx-0 px-1"
              ><v-btn @click="GetQuestions(item)" color="#833133" dark
                ><v-icon>mdi-magnify</v-icon></v-btn
              ></v-col
            > -->
                </v-row>
              </template>
            </v-data-table>
          </v-card>
        </v-row>
        <v-row align="center" justify="end">
          <v-btn @click="cancel()" class="mr-4 mt-4">กลับ</v-btn>
          <!-- <v-btn @click="submit()" color="primary">บันทึก</v-btn> -->
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";

import ExportVoucher from "@/views/ExportExcel/ExportVoucher";
export default {
  components: { ExportVoucher },
  data() {
    return {
      search: "",
      id: "",
      headersVoucher: [
        {
          text: "รหัสบัตรเงินสด",
          value: "voucherCode",
          align: "center",
        },
        { text: "ราคาคูปอง", value: "voucherPrice", align: "center" },
        {
          text: "ใช้งาน",
          value: "activeFlag",
          align: "center",
        },
        {
          text: "ใช้งานโดย",
          value: "name",
          align: "center",
        },
        // activeFlag: (...)
        // createdAt: (...)
        // id: (...)
        // updatedAt: (...)
        // user: (...)
        // userId: (...)
        // voucherCode: (...)
        // voucherEnd: (...)
        // voucherId: (...)
        // voucherPrice: (...)
        // voucherStart: (...)
      ],
      items: [],
      // discountType: "",
      voucherName: "",
      voucherPrice: null,
      voucherAmt: null,
      activeFlag: true,
      eppFlag: false,
      company: "",
      itemsCompany: [],
      // pic1: "",
      // picture_1: "",
      // showImage: "",
      // imageName1: "",
      voucherUsedAmt: 0,
      datefrom: new Date().toISOString().substr(0, 10),
      dateto: new Date().toISOString().substr(0, 10),
      menufrom: false,
      menuto: false,
      firstDate: new Date("2021-06-01").toISOString().slice(0, 10),
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
    };
  },
  created() {
    this.getDropdown();
    var Voucherdata = JSON.parse(
      Decode.decode(localStorage.getItem("Voucherdata"))
    );
    this.id = Voucherdata.id;
    console.log("id", this.id);
    this.findOne();
  },
  methods: {
    async findOne() {
      const getdata = await this.axios.get(
        `${process.env.VUE_APP_API}/vouchers/` + this.id
      );
      let data = getdata.data.data;
      console.log("data", data);
      this.voucherName = data.voucherName;
      // this.discountType = data.discountType;
      this.voucherPrice = data.voucherPrice;
      this.voucherAmt = data.voucherAmt;
      // this.couponAmt = data.couponAmt;
      this.datefrom = data.voucherStart;
      this.dateto = data.voucherEnd;
      this.eppFlag = data.eppFlag;
      this.activeFlag = data.activeFlag;
      // this.company = data.companyId;
      this.items = data.voucherList;
      if (data.companyId) {
        this.company = data.company.companyName;
      }
      this.voucherUsedAmt = getdata.data.voucherUsedAmt;
      console.log("voucherUsedAmt", this.voucherUsedAmt);
    },
    async getDropdown() {
      // const cateDropdown = await this.axios.get(
      //   `${process.env.VUE_APP_API}/categories`
      // );
      // const typeDropdown = await this.axios.get(
      //   `${process.env.VUE_APP_API}/types`
      // );
      // const brandDropdown = await this.axios.get(
      //   `${process.env.VUE_APP_API}/brands`
      // );
      const companyDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/companies`
      );
      // const productDropdown = await this.axios.get(
      //   `${process.env.VUE_APP_API}/products`
      // );
      // this.itemsCategory = cateDropdown.data.data;
      // this.itemsType = typeDropdown.data.data;
      // this.itemsBrand = brandDropdown.data.data;
      this.itemsCompany = companyDropdown.data.data;
      // this.itemsProduct = productDropdown.data.data;
      console.log("itemsCompany", this.itemsCompany);
      //////////get dropdown list/////////////
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManageVoucher");
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        const data = {
          id: this.id,
          category: this.categoryname,
          description: this.categoryDescription,
          activeFlag: this.activeFlag,
          categoryImg: this.pic1,
        };
        var user = JSON.parse(
          Decode.decode(localStorage.getItem("userHealthAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E` },
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/categories/` + this.id,
          data,
          auth
        );
        console.log("createCate", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `สร้างหมวดหมู่สินค้าสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageCategory");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `สร้างหมวดหมู่สินค้าไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>